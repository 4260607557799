<template>
  <AppHeaderDropdown right no-caret v-if="notifications.length">
    <template slot="header">
      <i class="icon-bell"></i><b-badge pill variant="danger">{{ notifications.length }}</b-badge>
    </template>
    <template v-if="notifications.length" slot="dropdown">
      <b-dropdown-header tag="div" class="dropdown-menu-lg text-center"><strong>You have {{notifications.length}} notifications</strong></b-dropdown-header>
      <b-dropdown-item v-for="(notification, index) in notifications" :to="notification.link" :key="index">{{ notification.label }}</b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>
<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
export default {
  name: 'DefaultHeaderDropdownNotif',
  components: {
    AppHeaderDropdown
  },
  data: () => {
    return {
      notifications: []
    }
  }
}
</script>
