<template>
  <b-form-select v-if="currentUser.businesses.length > 1" v-model="selected">
    <b-form-select-option
      v-for="business in currentUser.businesses"
      :key="business.id"
      :value="business.id"
    >AREA: {{ business.areaName }} ( /{{ business.uriName }} )</b-form-select-option>
  </b-form-select>
</template>

<script>
export default {
  name: 'SelectActiveBusiness',
  data () {
    return {
      selected: this.currentBusiness?.id || null
    }
  },
  watch: {
    currentBusiness (val) {
      this.selected = val?.id || null
    },
    selected (val) {
      if (val && val !== this.currentBusiness?.id) {
        this.$store.dispatch('getBusiness', {
          id: val
        })
        if (this.$route.name !== 'Home') {
          this.$router.push({
            name: 'Home'
          })
        }
      }
    }
  },
}
</script>
