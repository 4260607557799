<template>
  <div>
    <div
      v-if="storeStates.global.isLoading"
      style="position:absolute;top:0;bottom:0;left:0;right:0;height:100%;padding-top:50%;z-index:999;opacity:0.8"
      class="bg-info d-flex justify-content-center pt-5"
    >
      <!-- working spinner -->
      <b-spinner label="Working"></b-spinner>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Working'
}
</script>