<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <i class="icon-user"></i>
    </template>
    <template slot="dropdown">
      <b-dropdown-item v-if="user" to="/me"><i class="fa fa-user" /> {{ user.fullName }}</b-dropdown-item>
      <!-- b-dropdown-item><i class="fa fa-wrench" /> Settings</b-dropdown-item -->
      <b-dropdown-divider />
      <template v-if="user.originalUser">
        <b-dropdown-item @click.prevent="revertLoginAs"><i class="fa fa-undo" /> Revert Account</b-dropdown-item>
      </template>
      <b-dropdown-item @click.prevent="logout"><i class="fa fa-lock" /> Logout</b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
export default {
  name: 'DefaultHeaderDropdownAccnt',
  components: {
    AppHeaderDropdown
  },
  data: () => {
    return { itemsCount: 42 }
  },
  computed: {
    user () {
      return this.$store.getters.currentUser
    }
  },
  methods: {
    revertLoginAs () {
      this.$store.dispatch('revertLoginAs').then(() => {
        this.$router.push({ name: 'home' })
      })
    },
    logout () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/auth/login')
      })
    }
  }
}
</script>
